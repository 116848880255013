import { defineComponent as _defineComponent } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { SYSTEM } from "@ems/locales/system";
import { onMounted, ref, watch } from "vue";
import AccountInformationForm from "@ems/containers/Tenant/TheForm/AccountInformation.Form.vue";
import CompanyInformationForm from "@ems/containers/Tenant/TheForm/CompanyInformation.Form.vue";
import DocumentsInformationForm from "@ems/containers/Tenant/TheForm/DocumentsInformation.Form.vue";
import BuyerInformationForm from "@ems/containers/Tenant/TheForm/BuyerInformation.Form.vue";
import {
  IAccountInformation,
  IBuyerInformation,
  ICompanyInformation,
  IDocumentsInformation,
  IRegisterClient,
} from "@/models/Tenant";
import StatusRegisterClient from "@/components/Common/Status/StatusRegisterClient.vue";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import {
  STATUS_REGISTER_CLIENT,
  STATUS_TENANT,
} from "@ems/constants/account_registration_status";
import CompanyAccountManagementModule from "@ems/containers/CompanyAccountManagement/CompanyAccountManagement.module";
import { DOCTYPE } from "@ems/constants/doc_type";
import { RESUITE_ROLE } from "@ems/constants";
import RemarkCard from "@/components/Common/RemarkCard.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewDetail',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const tenantIdRoute = ref<string>();
const isReadOnly = ref(true);
const isAmend = ref(false);

const formData = ref<IRegisterClient>({
  AccountInformation: {
    ClientType: undefined,
    Email: "",
  },
  CompanyInformation: {
    Country: "",
    Province: "",
    CompanyRegistrationNumber: "",
    CompanyName: "",
    Address: "",
    PhoneCode: "",
    ContactNumber: "",
  },
  BuyerInformation: {
    PersonInCharge: "",
    Designation: "",
    BillingAddress: "",
    PhoneCode: "",
    BuyerContactNumber: "",
    BuyerEmail: "",
    AlternativeEmail: "",
  },
  DocumentInformation: {
    CompanyRegistrationDocument: "",
    LetterOfAuthorization: "",
    OtherRelevantDocuments: "",
  },
  Status: 0,
});
const formStatus = ref();
const accountData = ref<IAccountInformation>();
const companyData = ref<ICompanyInformation>();
const documentData = ref<IDocumentsInformation>();
const buyerData = ref<IBuyerInformation>();

const fetchRegisterDetail = async (tenantId: string) => {
  await TenantUserModule.getTenantRegisterDetailAction(tenantId ?? "");

  const dataRegisterDetail = TenantUserModule.dataTenantRegisterDetailGetter;
  if (dataRegisterDetail) {
    formData.value = dataRegisterDetail;
  }
  CompanyAccountManagementModule.getDataFilesGetter.map((file: any) => {
    const mappedFile = {
      name: file.Name,
      size: new Blob([file.Content]).size,
      status: "ready",
      raw: new Blob([file.Content], { type: file.Type }),
      uid: file.Id,
      url: file.Content,
    };
    switch (file.DocType) {
      case DOCTYPE[DOCTYPE.CompanyRegistrationDocument]:
        formData.value!.DocumentInformation.CompanyRegistrationDocument.push(
          mappedFile
        );
        break;
      case DOCTYPE[DOCTYPE.LetterOfAuthorization]:
        formData.value!.DocumentInformation.LetterOfAuthorization.push(
          mappedFile
        );
        break;
      case DOCTYPE[DOCTYPE.OtherRelevantDocuments]:
        formData.value!.DocumentInformation.OtherRelevantDocuments.push(
          mappedFile
        );
        break;
    }
  });
  formStatus.value = dataRegisterDetail.Status!;
};

const handleCancelAmend = () => {
  isAmend.value = false;
  isReadOnly.value = true;
};

const handleAmend = () => {
  isAmend.value = true;
  // isReadOnly.value = false;
  // tenantIdRoute.value = "";
};

onMounted(async () => {
  if (route.params.tenantId) {
    tenantIdRoute.value = route.params.tenantId;
    await fetchRegisterDetail(tenantIdRoute.value);
  }
});

watch(
  () => route.params.tenantId,
  async (newTenantId: any, oldTenantId: any) => {
    if (newTenantId && newTenantId !== oldTenantId) {
      tenantIdRoute.value = newTenantId;
      await fetchRegisterDetail(newTenantId);
    }
  }
);

const __returned__ = { route, router, tenantIdRoute, isReadOnly, isAmend, formData, formStatus, accountData, companyData, documentData, buyerData, fetchRegisterDetail, handleCancelAmend, handleAmend, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get SYSTEM() { return SYSTEM }, onMounted, ref, watch, AccountInformationForm, CompanyInformationForm, DocumentsInformationForm, BuyerInformationForm, get IAccountInformation() { return IAccountInformation }, get IBuyerInformation() { return IBuyerInformation }, get ICompanyInformation() { return ICompanyInformation }, get IDocumentsInformation() { return IDocumentsInformation }, get IRegisterClient() { return IRegisterClient }, StatusRegisterClient, get TenantUserModule() { return TenantUserModule }, get STATUS_REGISTER_CLIENT() { return STATUS_REGISTER_CLIENT }, get STATUS_TENANT() { return STATUS_TENANT }, get CompanyAccountManagementModule() { return CompanyAccountManagementModule }, get DOCTYPE() { return DOCTYPE }, get RESUITE_ROLE() { return RESUITE_ROLE }, RemarkCard }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})